.notification-badge-container{
  width: 560px;
  max-height: 600px;
  overflow-y: auto;
  padding: 0;
}

.notification-badge-header-container{
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #CCC;
}

.notification-badge-header{
  font-size: 20px;
  font-weight: bold;
}

.view-all-button{
  font-size: 16px !important;
  font-weight: bold!important;
  color:#6956BA!important;
}

#notification-menu{
  border-radius: 0;
}

.avatar{
  height: 40px !important;
  width: 40px !important;
  background-color: #DDE0FF!important;
  color: #666!important;
  text-align: center!important;
  font-family: Roboto!important;
  font-size: 20px!important;
  font-style: normal!important;
  font-weight: 300!important;
  line-height: normal!important;
}

.notification-details{
  cursor: pointer;
  display: flex;
  gap:10px;
  color: #253858;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border-bottom: 1px solid #E7E9ED;
  padding: 0.5rem 1rem;
  background-color: #F2F3FF;
}

span.notification-description{
  font-family: 'SF Pro Display', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #253858;
  margin-top: 4px;
}

.greyed-out{
  opacity:0.5;
  background-color: #FFFFFF
}
.greyed-out:hover{
  opacity:0.5;
}

.notification-details-time{
  color:  #74849C;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0.25rem;
}

.no-notification-container{
  color: #999999;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
